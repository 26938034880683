export default function () {
    const modals = document.querySelectorAll('.js-modal');
    modals.forEach((modal) => {
        const openButton = document.querySelector(`.js-open-modal[data-modal="${modal.id}"]`);
        const closeButton = modal.querySelector('.js-close-modal');
        const body = document.querySelector('body');

        const openModal = () => { modal.classList.add('open'); };
        const closeModal = () => { modal.classList.remove('open'); };

        if (openButton) { openButton.addEventListener('click', openModal); }
        if (closeButton) { closeButton.addEventListener('click', closeModal); }

        body.addEventListener('click', (event) => {
            const isClickInsideModal = modal.contains(event.target);
            const isClickOnOpenButton = openButton.contains(event.target);

            if (!isClickInsideModal && !isClickOnOpenButton) {
                closeModal();
            }
        });

        modal.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    });
}
